@import "../../../variables.module"

.DocsLink
  display: flex
  align-items: center
  gap: 4px
  font-family: Roboto, sans-serif
  font-size: 12px
  font-style: normal
  font-weight: 700
  text-decoration: none

.Button
  background-color: #416CDE
  color: white
  font-family: 'Roboto', sans-serif
  font-weight: 700 !important
  font-size: 12px
  border-radius: 6px !important
  box-shadow: $light-shadow

  display: flex
  align-items: center
  gap: 5px
  padding: 5px 10px

  &:hover
    background-color: #416CDE

  &:disabled
    background-color: rgba(0, 0, 0, 0.26)
