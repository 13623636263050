@import '../../../../variables.module'

.LicenseMetricContainer
  display: flex
  align-items: flex-start
  flex-direction: column
  gap: 10px
  padding: 8px
  border-radius: 6px
  margin-bottom: 10px

  .LicenseMetricTitle
    font-family: $text-font-family
    font-weight: 500
    color: $font-color
