@import '../../../variables.module'

.TrafficPageContainer
    position: relative
    padding-top: 10px
    display: flex
    flex-grow: 1
    overflow: hidden
    background-color: $data-background-color

.TrafficPageListContainer
    position: relative
    display: flex
    flex-grow: 1
    overflow: hidden
    padding-left: 16px
    padding-right: 8px
    flex-direction: column

.EntryDetailedContainer
  height: 100%
  padding: 12px 16px
  border-radius: 4px
  background: $header-background-color
  box-shadow: $light-shadow-bottom
