.KflSyntaxReferenceContainer
    display: flex
    align-items: center
    justify-Content: space-between

#KflQueryFieldContainer
    border: 1px solid rgba(0, 0, 0, 0.23)
    border-radius: 4px

    :first-child
        border-radius: 4px
