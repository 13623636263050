.DashboardBanner
    position: relative
    width: 100%
    height: 100%
    padding-left: 80px
    display: flex
    align-items: flex-start
    flex-direction: column
    justify-content: space-evenly
    background: radial-gradient(59.87% 63.91% at 85.5% 86.51%, #627EF7 0%, #416CDE 100%)

    .LogoBackdrop
        position: absolute
        right: -35px
        bottom: -60px
        opacity: 0.2

.DashboardFeaturesList
    margin: 0
    padding: 0
    list-style: none
    display: flex
    flex-direction: column
    gap: 12px
    width: 80%

    li
        display: flex
        align-items: center
        gap: 10px
