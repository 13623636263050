@import '../../../variables.module'

.statusBarContainer
    display: flex
    flex-flow: row nowrap
    align-items: flex-start
    position: absolute
    top: 0
    left: 50%
    transform: translate(-50%, -3px)
    z-index: 902
    width: 42vw

.statusBar
    width: 100%
    background: $blue-color
    color: rgba(255,255,255,0.75)
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
    padding: 10px
    font-size: 14px
    transition: max-height 2s ease-out
    max-height: 32px
    overflow: hidden

    .podsCount
        display: flex
        align-items: center
        gap: 10px
        justify-content: center
        font-weight: 700
        text-align: center

        .podsCountText
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

        img
            margin-right: 10px
            height: 22px

    table
        width: 100%
        margin-top: 20px

        thead
            position: sticky
            top: 0
            background-color: $blue-color
            z-index: 1000

        tbody
            display: block
            margin-top: 10px
        tr
            display: table
            table-layout: fixed
            width: 100%
        th
            text-align: left
            padding-right: 5%
        td
            text-align: left
            padding-right: 5%

.expandedStatusBar
    max-height: 100vh
    padding-bottom: 15px
    border-bottom-right-radius: 8px

    .tableContainer
        margin-top: 10px
        max-height: 80vh
        overflow-y: auto
