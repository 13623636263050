.loadingOverlayContainer
    position: absolute
    display: flex
    width: 100%
    height: 100%
    top: 0
    left: 0
    align-items: center
    justify-content: center
    background-color: rgba(25, 25, 25, 0.5)
    z-index: 1000

.loadingOverlaySpinner
    width: 60px
    height: 60px
    top: 50%
    left: 50%
    z-index: 1000
    -webkit-animation: rotation .6s infinite linear
    -moz-animation: rotation .6s infinite linear
    -o-animation: rotation .6s infinite linear
    animation: rotation .6s infinite linear
    border-left: 6px solid rgba(0, 174, 239, 0.15)
    border-right: 6px solid rgba(0, 174, 239, 0.15)
    border-bottom: 6px solid rgba(0, 174, 239, 0.15)
    border-top: 6px solid rgba(0, 174, 239, 0.8)
    border-radius: 100%

@-webkit-keyframes rotation
    from
        -webkit-transform: rotate(0deg)

    to
        -webkit-transform: rotate(359deg)

@-moz-keyframes rotation
    from
        -moz-transform: rotate(0deg)

    to
        -moz-transform: rotate(359deg)

@-o-keyframes rotation
    from
        -o-transform: rotate(0deg)

    to
        -o-transform: rotate(359deg)

@keyframes rotation
    from
        transform: rotate(0deg)

    to
        transform: rotate(359deg)
