@import '../../../variables.module'

.TrafficStatsFooter
  position: relative
  z-index: 3
  margin-left: 16px
  margin-right: 16px
  padding-top: 10px
  padding-bottom: 5px
  display: flex
  align-items: center
  justify-content: space-between
  border-top: 1px solid #BCC6DD
  background-color: $data-background-color
