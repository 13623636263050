@import '../../../../variables.module'

.Bandwidth
  display: flex
  align-items: center
  padding: 2px 4px
  border-radius: 4px
  background-color: $data-background-color
  width: fit-content
  height: 15px
  max-height: 15px
