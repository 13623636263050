@import '../../../../variables.module'

.DuplicateBadge
  height: 16px
  padding: 4px 6px
  display: flex
  align-items: center
  gap: 4px
  box-sizing: border-box
  border-radius: 4px
  box-shadow: $light-white-shadow
  background-color: $light-gray-color-alt

.DuplicateBadgeText
  font-size: 10px
  font-weight: 600
  font-family: Roboto, sans-serif
  color: #ffffff
