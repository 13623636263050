@import '../../../../variables.module';

.EditorAiPromptSelectionLine {
  border: 1px solid $darker-warning-color;
  border-radius: 4px;
  background-color: $light-warning-color;
}

.EditorAiPromptUpdatedLine {
  border: 1px solid $success-color;
  border-radius: 4px;
  background-color: $light-success-color;
}

.EditorBlurred {
  filter: blur(10px)
}
