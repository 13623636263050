@import '../../../variables.module'

.ConsoleViewContainer
  position: relative
  padding-top: 10px
  display: flex
  flex-grow: 1
  overflow: hidden
  background-color: $data-background-color

.FillAllSpaceContainer
  box-sizing: border-box
  overflow: hidden
  display: flex
  flex-grow: 1
  flex-direction: column
  justify-content: space-between
  position: relative

.ConsoleTabsContainer
  box-sizing: border-box
  flex: 1
  display: flex
  align-items: center
  justify-content: stretch
  background-color: $github-editor-background-color-light
  border-top-left-radius: 4px
  overflow: hidden

.LogContainer
  transition: height 1s
  box-sizing: border-box
  height: 100%
  width: 100%
  border-bottom-left-radius: 4px
  border-bottom-right-radius: 4px
  overflow: hidden
