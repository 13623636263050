@import '../../variables.module'

.queryInputContainer
  .w-tc-editor-text
    padding-right: 0 !important

  .w-tc-editor-preview
    padding-right: 0 !important

.appliedFiltersTooltip
  padding: 0
  border: 1px solid $light-blue-color
  border-radius: 2px
  background-color: $main-background-color
  box-shadow: $light-shadow

.appliedFiltersTooltipArrow
  color: $light-blue-color
