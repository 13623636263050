@import "../../../variables.module"

.TimezoneModal
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 500px
  padding: 32px
  border-radius: 6px
  background-color: $main-background-color
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 30px
