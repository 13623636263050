@import '../../../variables.module'

.DropdownButton
  transition: 0.2s
  cursor: pointer

  width: 30px
  height: 30px

  padding: 3px
  border: 0
  border-radius: 6px
  background: $header-background-color

  &:hover
    transition: 0.2s
    background: $data-background-color

.Dropdown
  display: flex
  align-items: flex-start
  flex-direction: column
  gap: 10px

  margin: 0
  padding: 0
  padding: 8px
  list-style: none

  border-radius: 6px
  border: 1px solid $light-blue-color
  background-color: $main-background-color
  box-shadow: $light-shadow

.DropdownItem
  cursor: pointer
  box-sizing: border-box

  transition: 0.3s

  width: 100%
  height: 35px

  padding-left: 8px
  padding-right: 16px

  display: flex
  align-items: center
  gap: 10px

  border-radius: 6px

  &:hover
    transition: 0.3s
    background: $data-background-color

  .DropdownItemText
    color: $blue-color

    font-family: Roboto
    font-size: 12px
    font-style: normal
    font-weight: 700
    line-height: 14px

    white-space: nowrap

.DropdownItemDisabled
  cursor: auto

  &:hover
    background: unset

  .DropdownItemText
    color: $light-gray-color
