@import "../../../variables.module"
@import "../../../components"

$modalMargin-from-edge : 35px

.closeIcon
  position: absolute
  right: 20px
  top: 20px

.modalContainer
  display: flex
  height: 92.8%
  background: #F0F5FF
  padding: 10px

.headerContainer
  width: 100%
  background: white
  display: flex
  align-items: center

.headerSection
  display: flex
  align-content: center
  align-items: center
  margin-left: $modalMargin-from-edge
  margin-bottom: 10px
  margin-top: 10px

  & .title
    font-size: 28px
    color: $font-color
    font-weight: 600
    margin-right: 35px
    white-space: nowrap

.indicatorContainer
    border-radius: 50%
    padding: 4px
    margin-left: 10px
    height: 8px
    width: 8px

.indicator
    height: 8px
    width: 8px
    border-radius: 50%

@keyframes biggerIndication
    0%
        transform: scale(2.0)
    100%
        transform: scale(0.8)

.greenIndicatorContainer
    border: 2px #6fcf9770 solid

.greenIndicator
    background-color: #27AE60
    animation: biggerIndication 1.5s ease-out 0s alternate infinite none running

.orangeIndicatorContainer
    border: 2px #fabd5970 solid

.orangeIndicator
    background-color: #ffb530
    animation: biggerIndication 1.5s ease-out 0s alternate infinite none running

.greyIndicatorContainer
    border: 2px #8b8b8b45 solid

.greyIndicator
    background-color: #8c8c8c

.redIndicatorContainer
    border: 2px #ff3a3045 solid

.redIndicator
    background-color: #ff3a30
