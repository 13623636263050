@import '../../../../variables.module'

.ScriptingTemplatesOverlay
  z-index: 1000
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center

.ScriptingTemplatesContainer
  overflow-y: auto
  width: 80vw
  max-height: 65vh
  border-radius: 4px
  box-shadow: $light-shadow

  .ScriptingTemplate
    transition: border-color, scale 0.2s ease-in-out
    cursor: pointer
    box-sizing: border-box
    padding: 10px 16px
    height: 120px
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    gap: 5px
    border-radius: 4px
    border: 1px solid transparent

    &:hover
      scale: 1.03
      border-color: $lighter-gray-color
