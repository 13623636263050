@import '../../variables.module'

\:global
  .TrafficEntry
    position: relative
    width: 100%
    box-sizing: border-box
    display: flex
    gap: 3px
    align-items: center
    border-radius: 4px
    cursor: pointer
    border: solid 1px transparent
    margin-right: 10px

    &:hover
      border: solid 1px lighten(#4253a5, 20%)

    &.selected
      border: 1px $blue-color solid

    &.error
      .DataText
        color: $darkest-failure-color

  .TrafficEntryResolution
    display: flex
    flex-direction: column
    align-items: flex-start
    width: 100%
    padding-left: 12px
    margin-right: 10px
    overflow: hidden

  .TrafficEntrySummary
    display: flex
    align-items: center
    gap: 10px
    padding-top: 4px

  .TrafficEntryIps
    display: flex
    align-items: center
    gap: 5px
    margin-right: 15px

  .DataText
    text-overflow: ellipsis
    white-space: nowrap
    color: $dark-slate-color
    font-family: 'Roboto', sans-serif
    font-size: 12px
    font-weight: 400
    letter-spacing: -0.48px

  .MonoText
    text-overflow: ellipsis
    white-space: nowrap
    color: $dark-slate-color
    font-family: 'Roboto Mono', monospace
    font-size: 12px
    font-weight: 500
    letter-spacing: -0.95px

    &.Smaller
      font-size: 11px
      font-weight: 500
      letter-spacing: -0.95px

  .DataBadge
    font-size: 12px
    font-weight: 700
    font-family: Source Sans Pro, sans-serif
    line-height: 1
    color: $dark-slate-color

  .StatusCodeContainer
    margin-left: 10px

  .NamespaceContainer
    position: relative
    box-sizing: border-box
    height: 20px
    padding: 2px 4px
    display: flex
    align-items: center
    gap: 4px
    border-radius: 4px

  .padlock
    position: absolute
    top: -25px
    left: 5px

  .TimestampDivider
    width: 2px
    min-width: 2px
    max-width: 2px
    height: 50px
    background-color: $data-background-color
