@import '../../../variables.module'

.title
    display: flex
    align-items: center
    font-weight: 600

.button
    display: flex
    align-content: center
    justify-content: space-around
    width: .75rem
    height: .75rem
    border-radius: 4px
    font-size: .75rem
    line-height: 0.92
    margin-right: .5rem
    font-weight: 800
    color: $main-background-color
    background-color: $light-blue-color
    &.expanded
        @extend .button
        line-height: .75rem
        background-color: $blue-color

.dataLine
    font-size: .75rem
    line-height: 1.2
    margin-bottom: -2px

    .dataKey
        color: $font-color
        margin: 0 0.5rem 0 0
        text-align: right
        overflow: hidden
        text-overflow: ellipsis
        width: 1%
        max-width: 15rem
        font-weight: 700

    .rulesTitleSuccess
        color: #0C0B1A

    .rulesMatchedSuccess
        background: #E8FFF1
        padding: 5px
        border-radius: 4px
        color: #219653
        font-style: normal
        font-size: 0.7rem
        font-weight: 600

    .rulesMatchedFailure
        background: #FFE9EF
        padding: 5px
        border-radius: 4px
        color: #DB2156
        font-style: normal
        font-size: 0.7rem
        font-weight: 600

    .dataValue
        color: $font-color
        margin: 0
        font-weight: normal
        > span:first-child
            word-break: break-all
            max-width: calc(100% - 1.5rem)
        > span:nth-child(2)
            border-radius: .2rem
            background-color: #3f3f3f
            display: block
            margin-left: .5rem
            margin-right: 0
            transition: all .3s
            width: 1rem
            height: 1rem
            &:hover
                background-color: #5a5a5a
            img
                position: relative
                width: 100%

.collapsibleContainer
    border-top: 1px solid $light-blue-color
    padding: 1rem
    background: none
    table
        width: 100%
        tr td:first-child
            white-space: nowrap
            padding-right: .5rem

.noRules
    padding: 0 1rem 1rem
