@import "../../../variables.module"

.LicenseInfoModal
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  display: flex
  flex-direction: column
  align-items: stretch

  width: 350px
  padding: 12px
  border-radius: 8px
  background-color: $header-background-color

  .TitleContainer
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 15px

    .TitleText
      font-family: $text-font-family
      color: $blue-color
      font-weight: 700
      text-transform: capitalize
