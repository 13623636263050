@import '../../../variables.module'

.TrafficSettingsModal
  min-width: 700px
  max-width: 920px
  max-height: 90vh
  overflow: auto
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  padding: 32px
  box-sizing: border-box
  border-radius: 6px
  background-color: $main-background-color
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 30px
