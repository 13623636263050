@import '../../variables.module'

.protocol
    border-radius: 4px
    border: solid 1px $light-gray-color
    padding: 1px 4px
    font-family: 'Roboto', sans-serif
    font-size: 12px
    font-weight: 700
    box-sizing: border-box
    height: 18px
    white-space: nowrap

    &.filterPlate
        border-color: #bcc6dd20
        color: #a0b2ff
        font-size: 10px

.noSelect
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
