@import '../../../../variables.module'
.UnresolvedFieldBadge
  display: flex
  align-items: center
  gap: 2px
  padding: 1px 4px
  border-radius: 4px
  border: 2px dashed $data-background-color
  width: fit-content

.SimplifiedBadge
  display: flex
  align-items: center
  gap: 2px
  border-radius: 4px
  width: fit-content
