@import '../../../../variables.module'

.SettingSection
  transition: all .2s ease-in
  display: flex
  flex-basis: auto
  flex-direction: column
  justify-content: space-between
  width: 100%
  box-sizing: border-box
  padding: 4px
  border-radius: 6px
  border: 1px solid $lightest-slate-color
  box-shadow: $light-spread-shadow-bottom

  &::before
    display: none
