.Tooltip
  border: 1px solid
  border-radius: 2px
  padding: 8px
  color: #0C0B1A
  max-width: 469px
  background-color: #BCCEFD
  box-shadow: 0px 10px 30px 0px #8F9BB280
  font-family: 'Source Sans Pro', sans-serif
  font-size: 11px
  font-weight: 400
  line-height: 14px
  letter-spacing: 0.03em
