@import './variables.module'

@import '~@fontsource/source-sans-pro/200.css'
@import '~@fontsource/source-sans-pro/300.css'
@import '~@fontsource/source-sans-pro/400.css'
@import '~@fontsource/source-sans-pro/600.css'
@import '~@fontsource/source-sans-pro/700.css'
@import '~@fontsource/source-sans-pro/900.css'

@import '~@fontsource/roboto/100.css'
@import '~@fontsource/roboto/300.css'
@import '~@fontsource/roboto/400.css'
@import '~@fontsource/roboto/500.css'
@import '~@fontsource/roboto/700.css'
@import '~@fontsource/roboto/900.css'

@import '@fontsource/roboto-mono/400.css'
@import '@fontsource/roboto-mono/500.css'

@import '@fontsource/noto-sans/300.css'
@import '@fontsource/noto-sans/400.css'
@import '@fontsource/noto-sans/500.css'

#root
  height: 100%

html,
body
  height: 100%

body
  font-family: 'Source Sans Pro', sans-serif
  font-weight: 400
  font-size: 90%
  color: #fff
  background-color: #313346
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  margin: 0
  padding: 0

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace

.uppercase
  text-transform: uppercase

/****
 * Button
 ***/
button
  span
    line-height: 1
  &:not(.MuiFab-root)
    &.MuiButtonBase-root
      box-sizing: border-box
      font-weight: 500
      line-height: 1
      letter-spacing: 0.02857em
      text-transform: uppercase
      img:not(.custom)
        max-width: 13px
        max-height: 13px

    &.tiny
      min-width: 0

  .MuiButton-startIcon
    margin: 0

.themeButton
  margin: 2px 0px 0px 0px
  font-weight: 600 !important
  border-radius: 6px
  text-transform: none !important
  box-shadow: $light-shadow
  border: 1px solid transparent

  &.primary
    background-color: $blue-color
    color: #fff

  &.secondary
    background-color: transparent
    color: $blue-color
    border: 1px solid $blue-color

  &.success
    background-color: $success-color
    color: #fff

    &.outlined
      background-color: transparent
      color: $success-color
      border: 1px solid $success-color
      box-shadow: none

  &.warning
    background-color: $warning-color
    color: #fff

  &.error
    background-color: $failure-color
    color: #fff

    &.outlined
      background-color: transparent
      color: $failure-color
      border: 1px solid $failure-color
      box-shadow: none

  &.white
    border: 0
    background-color: #fff
    color: $gray-color

    &.secondary
      background-color: $slate-color
      color: $lightest-gray-color
      box-shadow: none

  &.black
    border: 0
    background-color: $lighter-gray-color
    color: #fff

    &.secondary
      background-color: $light-gray-blue-color
      color: $slate-color
      box-shadow: none

  &.icon
    padding: 6px
    min-width: unset
    width: 26px
    max-height: 26px
    border: 0
    box-shadow: none

  &.gray
    background-color: $gray-color
    color: #fff

  &.light-gray
    background-color: $light-gray-color
    color: #fff

  &.slate
    background-color: $slate-color
    color: #fff

  &:disabled, &.disabled
    opacity: 0.6

/****
 * Select
 ***/
select
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat
  background-position: calc(100% - 0.75rem) center !important
  -moz-appearance: none !important
  -webkit-appearance: none !important
  appearance: none !important
  padding-right: 1rem !important

.select
  display: flex
  align-items: center
  justify-content: flex-start
  color: white
  .MuiInput-underline
    &::before,
    &::after
      display: none
      content: ''

    .MuiSelect-root
      &.MuiSelect-select
        border-radius: 20px
        cursor: pointer
        min-width: 2rem
        font-weight: normal
        padding: 3px 16px 4px 12px
        border: 1px solid #BCC6DD

    .MuiInputBase-input
      border-radius: 20px
      background-color: $main-background-color
      cursor: pointer
      padding-top: 0
      padding-bottom: 0
      font-size: 12px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      letter-spacing: normal
      text-align: left
      line-height: 1.25
      min-height: initial
    .MuiSelect-icon
      top: 50%
      transform: translateY(-50%)
      right: 5px
      position: absolute
      pointer-events: none
      &.MuiSelect-iconOpen
        transform: translateY(-50%) rotate(180deg)

  .ellipsis
    display: block
    overflow: hidden
    white-space: nowrap
    width: 100px
    text-overflow: ellipsis

  .selectLabel
    margin-right: 8px

  &.labelOnTop
    flex-direction: column
    align-items: flex-start
    .selectLabel
      margin-right: 0
      margin-bottom: 4px

/****
 * Paper/List/Menu list
 ***/
.MuiList-root
  padding: 0
  &.MuiMenu-list
    border-radius: 4px
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5)
    .MuiListItem-root
      &.MuiMenuItem-root
        padding: 14px
        font-size: 11px
        font-stretch: normal
        font-style: normal
        line-height: 1.25
        &.Mui-selected
          font-weight: 600
          background-color: $data-background-color

// scroll-bar css

::-webkit-scrollbar
  width: 6px
  height: 6px

::-webkit-scrollbar-thumb
  background-color: $light-blue-color
  border-radius: 10px

::-webkit-scrollbar-button
  display: none

::-webkit-scrollbar-corner
  display: none
