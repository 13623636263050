@import '../../variables.module'

.TrafficPage
    width: 100%
    display: flex
    flex-direction: column
    overflow: hidden
    flex-grow: 1
    height: calc(100% - 70px)

    .TrafficPageHeader
        position: relative
        padding: 0 24px
        display: flex
        align-items: center
        background-color: $header-background-color
        justify-content: center

        .TrafficPageStreamStatus
            position: absolute
            left: 16px
            display: flex
            align-items: center

        .TrafficPageCapturingStatus
            position: absolute
            left: 245px
            display: flex
            align-items: center

        .TrafficPageNavTab
            padding: 16px 24px
            font-family: 'Roboto', sans-serif
            font-size: 12px
            font-weight: 500
            letter-spacing: 0.05em
            color: $slate-color

        .TrafficPageNavTabSelected
            background-color: $data-background-color
            font-weight: 800
            color: $gray-color


.TrafficPageHeaderImage
    width: 22px
    height: 22px

.TrafficPageHeaderText
    margin-left: 10px
    font-family: 'Source Sans Pro', serif
    font-size: 14px
    font-weight: bold
    color: #f7f9fc

.TrafficPageHeaderActions
    margin-left: auto

.TrafficPageHeaderActionsImage
    width: 22px
    height: 22px
    cursor: pointer
    padding-right: 1.2vw
    margin-left: auto
    transform: translate(0 ,25%)

.TrafficPageViewer
    height: 96.5%
    overflow: auto

    > iframe
        width: 100%
        height: 96.5%
        display: block
        overflow-y: auto

.TrafficContent
    box-sizing: border-box
    height: calc(100% - 60px)
    overflow: scroll

.TrafficPageDetailContainer
    width: 45vw
    background-color: #171c30
    flex: 0 0 50%
    padding: 12px 24px

.indicatorContainer
    border-radius: 50%
    padding: 4px
    margin-left: 10px

.indicator
    height: 8px
    width: 8px
    border-radius: 50%

@keyframes biggerIndication
    0%
        transform: scale(2.0)
    100%
        transform: scale(0.8)

.greenIndicatorContainer
    border: 2px #6fcf9770 solid

.greenIndicator
    background-color: #27AE60
    animation: biggerIndication 1.5s ease-out 0s alternate infinite none running

.orangeIndicatorContainer
    border: 2px #fabd5970 solid

.orangeIndicator
    background-color: #ffb530

.redIndicatorContainer
    border: 2px #ff3a3045 solid

.redIndicator
    background-color: #ff3a30

.connectionText
    display: flex
    align-items: center
    height: 17px
    font-size: 16px

.playPauseIcon
    cursor: pointer
    margin-right: 15px
    height: 30px

.footer
  display: flex
  justify-content: space-between
  border-top: 1px solid #BCC6DD
  align-items: center
  padding-top: 10px
