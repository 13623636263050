@import '../../../variables.module'

.ScriptingViewContainer
  position: relative
  padding-top: 10px
  display: flex
  flex-grow: 1
  overflow: hidden
  background-color: $data-background-color

.FillAllSpaceContainer
  box-sizing: border-box
  overflow: hidden
  display: flex
  flex-grow: 1
  flex-direction: column
  justify-content: space-between
  position: relative

.SidebarContent
  position: absolute
  top: 0
  left: 0
  right: 0
  height: 100%
  transition: left 0.3s ease-in-out

.SidebarScripts
  height: calc(100% - 100px)
  overflow-x: hidden
  overflow-y: auto
  margin-top: 20px
  display: flex
  flex-direction: column
  gap: 5px

  .SidebarScriptGroup
    margin: 0
    display: flex
    align-items: center
    gap: 5px
    font-family: $text-font-family
    font-size: 12px
    font-weight: 600
    text-align: left
    text-transform: uppercase
    white-space: nowrap
    color: $light-gray-color

.ScriptBrewAiPanel
  overflow: visible !important
  position: relative

  &:after
    position: absolute
    left: -10px
    top: 50%
    transform: translateY(-50%)
    display: table
    cursor: ew-resize
    content: " "
    width: 5px
    height: 55px
    border-radius: 0 4px 4px 0
    background: $slate-color
