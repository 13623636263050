@import '../../../../variables.module'

.ConsoleTab
  transition: background-color 0.3s
  position: relative
  cursor: pointer
  box-sizing: border-box
  height: 40px
  max-height: 40px
  background-color: $github-editor-background-color-light
  flex: 0.5
  display: flex
  align-items: center
  justify-content: space-between
  gap: 5px
  overflow: hidden
  border-right: 1px solid $slate-color
  border-bottom: 1px solid $slate-color

  &:hover:not(.ConsoleTabSelected)
    background-color: $github-editor-background-color

  &.ConsoleTabSelected
    flex: 1
    border-bottom: 0
    background-color: $github-editor-background-color

    .ConsoleTabText
      font-size: 16px
      font-weight: 600
      color: $main-background-color

  &:first-child
    border-top-left-radius: 4px

  .ConsoleTabText
    transition: color 0.2s
    margin: 0
    padding: 10px 16px
    font-size: 14px
    font-weight: 500
    font-family: $text-font-family
    text-align: center
    color: $lightest-gray-color
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

    &.DropdownTabTitle
      font-weight: 600

.DropdownTab
  width: 100%
  transition: scale, background-color 0.2s
  position: relative
  cursor: pointer
  box-sizing: border-box
  border-radius: 6px
  max-width: 300px
  height: 40px
  min-height: 40px
  max-height: 40px
  padding: 10px 16px
  background-color: $dark-slate-color
  overflow: hidden

  &:hover
    scale: 1.01
    outline: 1px solid $slate-color
    background-color: $github-editor-background-color

  .DropdownTabText
    transition: color 0.2s
    margin: 0
    font-size: 14px
    font-weight: 600
    font-family: $text-font-family
    text-align: left
    color: $lightest-gray-color
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
