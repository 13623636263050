@import '../../../variables.module'

.base
    box-sizing: border-box
    border-radius: 4px
    width: 35px
    height: 18px
    font-family: 'Roboto', sans-serif
    font-size: 12px
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    font-weight: 700

.neutral
    background: gray
    color: black

.success
    background: $success-color
    color: white

.failure
    background: $failure-color
    color: white
