@import "../../../../variables.module"

.LicenseBadge
  display: flex
  align-items: center
  gap: 4px
  padding: 3px 6px
  border-radius: 4px
  &:hover
    cursor: pointer
