@import '../../../../variables.module';

.Textarea {
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  max-height: 300px;
  min-width: 100%;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  overflow: auto !important;

  &.Dark {
    color: $light-gray-blue-color;
    background: $github-editor-background-color;
    border: 1px solid $slate-color;
    box-shadow: $light-spread-shadow-bottom;
  }

  &.Light {
    color: $gray-color;
    background: $light-gray-blue-color;
    border: 1px solid $lighter-gray-color;
    box-shadow: $light-spread-shadow-bottom;
  }

  &.InEditor {
    min-height: 90px;
    max-height: 90px;
    min-width: 400px;
    max-width: 90%;
  }

  &::placeholder {
    color: $light-gray-color-alt;
    font-weight: 500;
  }
}

.Textarea:hover {
  border-color: $light-slate-color;
}

.Textarea:focus {
  border-color: $slate-color;
  outline: none;
}

// firefox
.Textarea:focus-visible {
  outline: 0;
}

.AssistantProcessTextDark {
  background: linear-gradient(to right, $light-gray-color, $lightest-gray-color 10%, $light-gray-color);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 2s linear infinite;

  span {
    -webkit-text-fill-color: initial;
  }
}

.AssistantProcessTextLight {
  background: linear-gradient(to right, $gray-color, $light-gray-color 10%, $gray-color);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 2s linear infinite;

  span {
    -webkit-text-fill-color: initial;
  }
}

@keyframes shine {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}
