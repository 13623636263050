@import '../../../variables.module'

.react-lazylog-searchbar
  padding: 14px 14px !important
  background-color: $github-editor-background-color !important

  input
    flex: 1
    border-radius: 4px
    border-color: $slate-color
    background-color: $github-editor-background-color-light !important

.ReactVirtualized__Grid__innerScrollContainer
  div
    &:hover
      background-color: $github-editor-background-color-light !important
    a
      width: unset !important
      min-width: 20px !important
      margin-left: 15px
