@import '../../../../variables.module'

.SidebarScript
  transition: background-color, border-color, scale 0.2s ease-in-out
  cursor: pointer
  margin: 4px
  padding: 10px 8px
  display: flex
  align-items: center
  gap: 15px
  border-radius: 4px

  &:hover
    scale: 1.03
    border-color: $lighter-gray-color

  .SidebarScriptTitle
    margin: 0
    flex: 1
    font-family: $text-font-family
    font-size: 13px
    font-weight: 600
    text-align: left
    overflow: hidden
    text-overflow: ellipsis
