.FullResolutionData
  display: flex
  align-items: center
  gap: 5px
  width: 100%
  box-sizing: border-box
  flex-direction: row
  margin-top: 3px

.ResolutionDataContainer
  display: flex
  align-items: center
  gap: 10px
  border-radius: 4px
