@import '../../../variables.module'

.AnimatedUnreadDot
  position: relative
  width: 10px
  height: 10px

.CenterDot
  position: absolute
  top: 50%
  left: 50%
  width: 7px
  height: 7px
  border-radius: 50%
  transform: translate(-50%, -50%)
  animation: round-animation 1s infinite

.PulsatingCircle
  position: absolute
  top: 50%
  left: 50%
  width: 4px
  height: 4px
  border-radius: 50%
  background-color: transparent
  transform: translate(-50%, -50%)
  animation: pulsate 1s infinite

@keyframes round-animation
  0%, 100%
    transform: translate(-50%, -50%) scale(1)
  50%
    transform: translate(-50%, -50%) scale(1.1)

@keyframes pulsate
  0%
    transform: translate(-50%, -50%) scale(1)
    opacity: 1
  100%
    transform: translate(-50%, -50%) scale(2.8)
    opacity: 0 // Fades away
