$main-background-color: white;
$header-background-color: #F7F9FC;
$data-background-color: #ebf0f6;
$font-color: #0C0B1A;
$gray-font-color: #696969;
$light-font-color: #6f6f72;
$secondary-font-color: #8C8C8C;
$black-color: #000000;
$gray-color: #383838;
$slate-color: #5b667e;
$dark-slate-color: #4b535f;
$light-slate-color: rgb(220, 226, 235);
$lightest-slate-color: rgb(91, 102, 126, 0.12);
$blue-color: #416CDE;
$light-blue-color: #BCCEFD;
$dark-blue-color: #3558b6;
$light-gray-color: #8F9BB2;
$light-gray-blue-color: #eaeff5;
$lighter-gray-blue-color: #f3f7fd;
$light-gray-color-alt: #747e91;
$lighter-gray-color: #c3cadb;
$lightest-gray-color: #f6f8fb;
$success-color: #27AE60;
$light-success-color: #27ae5f2a;
$warning-color: #EDC800;
$light-warning-color: rgba(237, 201, 0, 0.22);
$darker-warning-color: #dab900;
$darkest-warning-color: #c8a900;
$high-contrast-warning-color: #ffd800;
$orange-color: #ed5700;
$light-orange-color: rgba(237, 87, 0, 0.85);
$lighter-orange-color: #ffbb93;
$failure-color: #EB5757;
$light-failure-color: rgba(235, 87, 87, 0.5);
$lighter-failure-color: rgba(235, 87, 87, 0.1);
$lightest-failure-color: rgb(255, 245, 245);
$darkest-failure-color: #550101;
$upgrade-color: #DB2156;
$light-upgrade-color: rgb(219 33 86 / 10%);

$dark-overlay-color: #bfbfbf;

$light-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
$light-white-shadow: 0px 6px 6px 1px rgb(246 248 251 / 55%);
$light-spread-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.16);
$light-spread-shadow-bottom: 0px 5px 9px -2px rgb(29 38 131 / 20%);
$light-shadow-bottom: 0px 3px 5px 0px rgb(153 153 153 / 16%);

$header-section-color : #fbfcfe;
$content-section-color: #f8f9fc;

$github-editor-background-color: #24292f;
$github-editor-background-color-light: #39404a;

$black-bottom-linear-gradient: linear-gradient(to top, #000000, #24292f7d);

$text-font-family: Source Sans Pro, Roboto, Lucida Grande, Tahoma, sans-serif;

:export {
  mainBackgroundColor: $main-background-color;
  headerBackgroundColor: $header-background-color;
  dataBackgroundColor: $data-background-color;
  fontColor: $font-color;
  grayFontColor: $gray-font-color;
  lightFontColor: $light-font-color;
  secondaryFontColor: $secondary-font-color;
  blackColor: $black-color;
  grayColor: $gray-color;
  slateColor: $slate-color;
  darkSlateColor: $dark-slate-color;
  lightSlateColor: $light-slate-color;
  lightestSlateColor: $lightest-slate-color;
  lightGrayColor: $light-gray-color;
  lightGrayBlueColor: $light-gray-blue-color;
  lighterGrayBlueColor: $lighter-gray-blue-color;
  lightGrayColorAlt: $light-gray-color-alt;
  lighterGrayColor: $lighter-gray-color;
  lightestGrayColor: $lightest-gray-color;
  blueColor: $blue-color;
  lightBlueColor: $light-blue-color;
  darkBlueColor: $dark-blue-color;
  successColor: $success-color;
  lightSuccessColor: $light-success-color;
  warningColor: $warning-color;
  highContrastWarningColor: $high-contrast-warning-color;
  lightWarningColor: $light-warning-color;
  darkerWarningColor: $darker-warning-color;
  darkestWarningColor: $darkest-warning-color;
  orangeColor: $orange-color;
  lightOrangeColor: $light-orange-color;
  lighterOrangeColor: $lighter-orange-color;
  failureColor: $failure-color;
  lightFailureColor: $light-failure-color;
  lighterFailureColor: $lighter-failure-color;
  lightestFailureColor: $lightest-failure-color;
  darkestFailureColor: $darkest-failure-color;
  lightUpgradeColor: $light-upgrade-color;
  darkOverlayColor: $dark-overlay-color;
  upgradeColor: $upgrade-color;
  lightShadow: $light-shadow;
  lightWhiteShadow: $light-white-shadow;
  lightSpreadShadow: $light-spread-shadow;
  lightSpreadShadowBottom: $light-spread-shadow-bottom;
  lightShadowBottom: $light-shadow-bottom;
  contentSectionColor: $content-section-color;
  githubEditorBackgroundColor: $github-editor-background-color;
  githubEditorBackgroundColorLight: $github-editor-background-color-light;
  blackBottomLinearGradient: $black-bottom-linear-gradient;
  textFontFamily: $text-font-family
}
