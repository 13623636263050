@import '../../../variables.module'

.backgroundWhiteContainer
  &.highlighterContainer
    pre
      code
        &:first-child
          background: $lighter-gray-blue-color

.backgroundDarkContainer
  &.highlighterContainer
    pre
      code
        &:first-child
          background: $github-editor-background-color

.highlighterContainer
    &.fitScreen
        pre
            max-height: 90vh
            overflow: auto

    pre
        code
            border-radius: 6px
            font-size: 0.75rem

            &:first-child
                margin-right: 0.75rem
                background: #F7F9FC

                .react-syntax-highlighter-line-number
                    color: rgb(98, 126, 247)



            &:last-child
                display: block

    code.hljs
        white-space: pre-wrap

    code.hljs:before
        counter-reset: listing

.hljsMarkerLine
    counter-increment: listing

.hljsMarkerLine:before
    content: counter(listing) "  "
    display: inline-block
    width: 3rem
    padding-left: auto
    margin-left: auto
    text-align: right
    opacity: .5
