.SamlSignInForm
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-evenly

.SamlNotice
    padding: 16px
    display: flex
    align-items: center
    gap: 12px
