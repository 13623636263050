.IpWithPort
  display: flex
  align-items: center
  width: 125px
  max-width: 125px
  position: relative

  &.Wider
    width: unset
    max-width: unset

.IpWithPortStart
  justify-content: flex-start

.IpWithPortEnd
  justify-content: flex-end
